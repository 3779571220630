



























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUott1721M6_Task24',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {text: '$sp$', value: 'twoBonds'},
        {text: '$sp^{2}$', value: 'fourBonds'},
        {text: '$sp^{3}$', value: 'sp3'},
        {text: '$p$', value: 'doesNotExist'},
      ],
    };
  },
};
